import { isBring, isMiist, isNordiskaGalleriet, isPosten } from '@glow/common/utils/env'
import { ThemeColors } from '@glow/entity-types'
import {
  AANonTextContrast,
  AARegularTextContrast,
  getA11yColor,
  getButtonTextColor,
  indexOfPrimary400,
  indexOfPrimary500
} from './colorA11yUtils'

export const DEFAULT_THEME = 'glow'
export const CUSTOM_THEME = 'custom-theme'
const THEMES = [
  DEFAULT_THEME,
  CUSTOM_THEME,
  'bring',
  'posten',
  'ikea',
  'ikea-dk',
  'miist',
  'nordiskagalleriet'
] as const
export type Theme = (typeof THEMES)[number]

export function isBringTheme(theme: Theme): boolean {
  return theme === 'bring'
}

export function isPostenTheme(theme: Theme): boolean {
  return theme === 'posten'
}

export function isBringOrPostenTheme(theme: Theme): boolean {
  return theme === 'bring' || theme === 'posten'
}

export function isCustomTheme(theme: Theme): boolean {
  return theme === CUSTOM_THEME
}

export function isIkeaTheme(theme: Theme): boolean {
  return theme === 'ikea' || theme === 'ikea-dk'
}

export function themeClassName(theme: Theme): string {
  const themeName = isIkeaTheme(theme) ? 'ikea' : isCustomTheme(theme) ? DEFAULT_THEME : theme
  return `theme-${themeName}`
}

export function getEnvironmentTheme(theme: Theme): Theme {
  return isCustomTheme(theme) ? getTheme() : theme
}

// Get the current theme from a prioritized list of sources.
// Use the "theme" query parameter to override the theme for testing.
export function getTheme(): Theme {
  return themeFromParam() || themeNameFromStorage() || themeFromEnv() || DEFAULT_THEME
}

const THEME_NAME_SESSION_STORAGE = 'theme-name'
const THEME_COLORS_SESSION_STORAGE = 'theme-colors'

const sessionStorageGetItem = (key: string) => {
  try {
    return sessionStorage.getItem(key)
  } catch (error) {
    console.error(error)
  }
}

// Get the theme from session storage.
// This value gets set when we fetch a shipment.
function themeNameFromStorage(): Theme | undefined {
  const theme = sessionStorageGetItem(THEME_NAME_SESSION_STORAGE)
  return theme && isValidTheme(theme) ? theme : undefined
}

export function themeColorsFromStorage(): ThemeColors | undefined {
  const themeColorsRaw = sessionStorageGetItem(THEME_COLORS_SESSION_STORAGE)
  return themeColorsRaw ? (JSON.parse(themeColorsRaw) as ThemeColors) : undefined
}

export function setThemeInStorage(name: Theme, themeColors: ThemeColors) {
  try {
    sessionStorage.setItem(THEME_NAME_SESSION_STORAGE, name)
    sessionStorage.setItem(THEME_COLORS_SESSION_STORAGE, JSON.stringify(themeColors))
  } catch (error) {
    console.error(error)
  }
}

// Get the theme from a URL parameter.
// Useful for overriding themes during testing.
export function themeFromParam(): Theme | undefined {
  const params = new URLSearchParams(window.location.search)
  const theme = params.get('theme') ?? ''

  if (isValidTheme(theme)) {
    return theme
  }
}

// Get the theme based on the current domain name.
// Most shipments will not have a theme specified in their data.
export function themeFromEnv(): Theme | undefined {
  if (isBring()) {
    return 'bring'
  } else if (isPosten()) {
    return 'posten'
  } else if (isMiist()) {
    return 'miist'
  } else if (isNordiskaGalleriet()) {
    return 'nordiskagalleriet'
  }
}

export function isValidTheme(s: string): s is Theme {
  return (THEMES as Readonly<string[]>).includes(s)
}

export const getThemeVariables = (theme?: Theme): ThemeColors => {
  switch (theme ?? getTheme()) {
    case 'ikea-dk':
    case 'ikea': {
      return {
        primary050: '#fafafa',
        primary100: '#f5f5f5',
        primary200: '#e5e5e5',
        primary300: '#d4d4d4',
        primary400: '#a3a3a3',
        primary500: '#000000',
        primary600: '#525252',
        primary700: '#525252',
        primary800: '#262626',
        primary900: '#171717',
        heading: '#000000'
      }
    }
    case 'bring': {
      return {
        primary050: '#F8FBF3',
        primary100: '#F0F9E5',
        primary200: '#C8DC8D',
        primary300: '#9BC473',
        primary400: '#7BC144',
        primary500: '#00643A',
        primary600: '#005730',
        primary700: '#053D22',
        primary800: '#002F19',
        primary900: '#042314',
        heading: '#000000'
      }
    }
    default: {
      // Glow default colors
      return {
        primary050: '#FFF9F8',
        primary100: '#FFF5F0',
        primary200: '#FFCAB7',
        primary300: '#FF8A5F',
        primary400: '#E32D22',
        primary500: '#980000',
        primary600: '#820000',
        primary700: '#691516',
        primary800: '#4A1011',
        primary900: '#3D0A0B',
        heading: '#000000'
      }
    }
  }
}

export const setColors = (themeVariables: ThemeColors, theme: Theme) => {
  const { heading, ...primaryColors } = themeVariables
  const themePrimaryColors = Object.values(primaryColors)

  const htmlElement = document.documentElement as HTMLElement | undefined
  if (!htmlElement) return
  htmlElement.style.setProperty('--color-primary-050', themeVariables.primary050)
  htmlElement.style.setProperty('--color-primary-100', themeVariables.primary100)
  htmlElement.style.setProperty('--color-primary-200', themeVariables.primary200)
  htmlElement.style.setProperty('--color-primary-300', themeVariables.primary300)
  htmlElement.style.setProperty('--color-primary-400', themeVariables.primary400)
  htmlElement.style.setProperty('--color-primary-500', themeVariables.primary500)
  htmlElement.style.setProperty('--color-primary-600', themeVariables.primary600)
  htmlElement.style.setProperty('--color-primary-700', themeVariables.primary700)
  htmlElement.style.setProperty('--color-primary-800', themeVariables.primary800)
  htmlElement.style.setProperty('--color-primary-900', themeVariables.primary900)
  htmlElement.style.setProperty(
    '--color-primary-400-a11y',
    getA11yColor(themePrimaryColors, indexOfPrimary400, AANonTextContrast, theme)
  )
  htmlElement.style.setProperty(
    '--color-primary-500-a11y',
    getA11yColor(themePrimaryColors, indexOfPrimary500, AARegularTextContrast, theme)
  )
  htmlElement.style.setProperty('--heading-color', themeVariables.heading)
  htmlElement.style.setProperty('--button-a11y-color', getButtonTextColor(themeVariables, theme))
}

export async function checkImageExists(url: string): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => {
      resolve(true)
    }
    img.onerror = () => {
      reject('Could not load image.')
    }
    img.src = url
  })
}
